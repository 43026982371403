import { Tabs } from '../../../core';
import { ProductCardCarousel, Spinner } from '../../../shared';
import personalizedRecommendations from '@ui/axios/searchSpring/personalizedRecommendations';
import { useQuery } from '@tanstack/react-query';
import { getCookie } from '@ui/hooks/useCookie';
import { recommendationProductsToSearchProducts } from '@ui/helpers/recommendationProductsToSearchProducts';
import env from '@ui/env';
import { GetProductsPricing } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';

const TabMappings = [
  {
    label: 'New',
    tags: 'home-page-new',
    placement: 'home-page',
    collection: 'new',
  },
  {
    label: 'Back In Stock',
    tags: 'home-page-back-in-stock',
    placement: 'home-page',
    collection: 'back-in-stock',
  },
];
const mapProducts = recommendationProductsToSearchProducts;
const HomeRecommendationsSection = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['homePageRecommendations'],
    queryFn: async () => {
      const requests = TabMappings.map((tab) =>
        personalizedRecommendations({
          tags: tab.tags,
          shopper: getCookie('shopper'),
          cart: getCookie('cart'),
          lastViewed: getCookie('lastViewed'),
        })
          .then(async (res) => {
            const products =  mapProducts(res?.data[0].results)

            if (env.MULTICURRENCY_FEATURE && products?.length > 0) {
              const productsPricing = await GetProductsPricing({
                first: products.length,
                query: products.map((p) => `id:${p.mappings.core.uid}`).join(' OR '),
                country: getCustomerCountry(),
              });

              products.forEach((product) => {
                const productEdges = productsPricing.data?.products.edges || [];
                const productPrice = productEdges.find((edge) => edge.node.id.split('/').pop() === product.mappings.core.uid);
                if (!productPrice) return;
                product.price = productPrice.node.priceRange.maxVariantPrice.amount;
                product.currency = productPrice.node.priceRange.maxVariantPrice.currencyCode;
                product.variant_compare_at_price = productPrice.node.compareAtPriceRange.maxVariantPrice.amount;
              });
            }

            return products;
          })
          .catch(() => undefined),
      );

      const results = await Promise.all(requests);

      if (!results.filter(Boolean).length) {
        throw new Error('No recommendations found.');
      } else {
        return results;
      }
    },
  });
  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Tabs
      defaultValue={TabMappings[0].collection}
      keepMounted={false}
      classNames={{
        tabsList: 'flex-nowrap justify-center md:justify-start',
        tab: 'w-full md:w-auto md:mr-8 data-[active]:underline data-[active]:underline-offset-8 group',
        tabLabel: 'font-normal group-data-[active]:font-bold',
      }}
    >
      <Tabs.List>
        {TabMappings.map((tab) => (
          <Tabs.Tab data-test="home-recommendations-tab" id={tab.label} key={tab.label} value={tab.collection}>
            {tab.label.toUpperCase()}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {TabMappings.map((tab, i) => (
        <Tabs.Panel key={tab.label} value={tab.collection} className="pt-8">
          <ProductCardCarousel
            layout="product"
            collection={tab.collection}
            withControls
            carousel={{
              tag: tab.tags,
              placement: tab.placement,
              products: data[i] || [],
            }}
            classNames={{
              root: '-mx-3 md:mx-0',
              viewport: 'px-3 md:px-0',
              slide: 'w-[70%] md:w-[18%]',
            }}
            height={570}
            width={380}
            align="start"
          />
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};

export default HomeRecommendationsSection;
