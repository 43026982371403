import React from 'react';
import env from '@ui/env';

const HomeTolstoySection = () => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const script = document.createElement('script');
    const noModuleScript = document.createElement('script');
    const tolstoyCarousel = document.createElement('tolstoy-carousel');
    if (ref && ref.current) {
      script.src = 'https://widget.gotolstoy.com/we/widget.js';
      script.setAttribute('type', 'module');
      script.setAttribute('async', 'true');
      script.setAttribute('data-shop', env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN);
      script.setAttribute('data-app-key', env.NEXT_PUBLIC_TOLSTOY_APP_KEY || '');

      noModuleScript.src = 'https://widget.gotolstoy.com/widget/widget.js';
      script.setAttribute('noModule', 'true');
      script.setAttribute('async', 'true');
      script.setAttribute('data-shop', env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN);
      script.setAttribute('data-app-key', env.NEXT_PUBLIC_TOLSTOY_APP_KEY || '');

      tolstoyCarousel.classList.add('tolstoy-carousel');
      tolstoyCarousel.setAttribute('data-publish-id', env.NEXT_PUBLIC_TOLSTOY_HOMEPAGE_ID || '');

      ref.current.appendChild(script);
      ref.current.appendChild(noModuleScript);
      ref.current.appendChild(tolstoyCarousel);
    }

    return () => {
      if (script) {
        script.remove();
      }
      if (noModuleScript) {
        noModuleScript.remove();
      }
      if (tolstoyCarousel) {
        tolstoyCarousel.remove();
      }
    };
  }, []);

  return <>{!!(env.SHOW_TOLSTOY && env.NEXT_PUBLIC_TOLSTOY_APP_KEY) && <div ref={ref}></div>}</>;
};

export default HomeTolstoySection;
